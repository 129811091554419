import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClassDetails,
  getClassStudents,
  getSubjectList,
} from "../../../redux/sclassRelated/sclassHandle";
import { deleteUser } from "../../../redux/userRelated/userHandle";
import { Box, Container, Typography, Tab, IconButton } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { resetSubjects } from "../../../redux/sclassRelated/sclassSlice";
import {
  BlueButton,
  GreenButton,
  PurpleButton,
} from "../../../components/buttonStyles";
import TableTemplate from "../../../components/TableTemplate";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SpeedDialTemplate from "../../../components/SpeedDialTemplate";
import Popup from "../../../components/Popup";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from "@mui/icons-material/PostAdd";

const ClassDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    subjectsList,
    sclassStudents,
    sclassDetails,
    loading,
    error,
    response,
    getresponse,
  } = useSelector((state) => state.sclass);

  const classID = params.id;

  useEffect(() => {
    dispatch(getClassDetails(classID, "Sclass"));
    dispatch(getSubjectList(classID, "ClassSubjects"));
    dispatch(getClassStudents(classID));
  }, [dispatch, classID]);

  if (error) {
    console.log(error);
  }

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");

  const deleteHandler = (deleteID, address) => {
    console.log(deleteID);
    console.log(address);
    setMessage("Sorry the delete function has been disabled for now.");
    setShowPopup(true);
    // dispatch(deleteUser(deleteID, address))
    //     .then(() => {
    //         dispatch(getClassStudents(classID));
    //         dispatch(resetSubjects())
    //         dispatch(getSubjectList(classID, "ClassSubjects"))
    //     })
  };

  const subjectColumns = [
    { id: "name", label: "Subject Name", minWidth: 170 },
    { id: "code", label: "Subject Code", minWidth: 100 },
  ];

  const subjectRows =
    subjectsList &&
    subjectsList.length > 0 &&
    subjectsList.map((subject) => {
      return {
        name: subject.subName,
        code: subject.subCode,
        id: subject._id,
      };
    });

  const SubjectsButtonHaver = ({ row }) => {
    return (
      <>
        <IconButton onClick={() => deleteHandler(row.id, "Subject")}>
          <DeleteIcon color="error" />
        </IconButton>
        <BlueButton
          variant="contained"
          onClick={() => {
            navigate(`/ngo/class/subject/${classID}/${row.id}`);
          }}
        >
          View
        </BlueButton>
      </>
    );
  };

  const subjectActions = [
    {
      icon: <PostAddIcon color="primary" />,
      name: "Add New Subject",
      action: () => navigate("/ngo/addsubject/" + classID),
    },
    {
      icon: <DeleteIcon color="error" />,
      name: "Delete All Subjects",
      action: () => deleteHandler(classID, "SubjectsClass"),
    },
  ];

  const ClassSubjectsSection = () => {
    return (
      <>
        {response ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <GreenButton
              variant="contained"
              onClick={() => navigate("/ngo/addsubject/" + classID)}
            >
              Add Subjects
            </GreenButton>
          </Box>
        ) : (
          <>
            <Typography sx={{ zIndex: 0 }} variant="h5" gutterBottom>
              Subjects List:
            </Typography>

            <TableTemplate
              buttonHaver={SubjectsButtonHaver}
              columns={subjectColumns}
              rows={subjectRows}
            />
            <SpeedDialTemplate actions={subjectActions} />
          </>
        )}
      </>
    );
  };

  const studentColumns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "rollNum", label: "Roll Number", minWidth: 100 },
  ];

  const studentRows = sclassStudents.map((student) => {
    return {
      name: student.name,
      rollNum: student.rollNum,
      id: student._id,
    };
  });

  const StudentsButtonHaver = ({ row }) => {
    return (
      <>
        <IconButton
          onClick={() => deleteHandler(row.id, "Student")}
          className="mx-2"
        >
          <PersonRemoveIcon color="error" />
        </IconButton>
        <BlueButton
          variant="contained"
          onClick={() => navigate("/ngo/students/student/" + row.id)}
          className="mx-2"
        >
          View
        </BlueButton>
        <PurpleButton
          variant="contained"
          className="mx-2"
          onClick={() => navigate("/ngo/students/student/attendance/" + row.id)}
        >
          Attendance
        </PurpleButton>
      </>
    );
  };

  const studentActions = [
    {
      icon: <PersonAddAlt1Icon color="primary" />,
      name: "Add New Student",
      action: () => navigate("/ngo/class/addstudents/" + classID),
    },
    {
      icon: <PersonRemoveIcon color="error" />,
      name: "Delete All Students",
      action: () => deleteHandler(classID, "StudentsClass"),
    },
  ];

  const ClassStudentsSection = () => {
    return (
      <>
        {getresponse ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "16px",
              }}
            >
              <GreenButton
                variant="contained"
                onClick={() => navigate("/ngo/class/addstudents/" + classID)}
              >
                Add Students
              </GreenButton>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5" gutterBottom>
              Students List:
            </Typography>

            <TableTemplate
              buttonHaver={StudentsButtonHaver}
              columns={studentColumns}
              rows={studentRows}
            />
            <SpeedDialTemplate actions={studentActions} />
          </>
        )}
      </>
    );
  };

  const ClassTeachersSection = () => {
    return <>Teachers</>;
  };

  const ClassDetailsSection = () => {
    const numberOfSubjects = subjectsList.length;
    const numberOfStudents = sclassStudents.length;

    return (
      <div className="bg-white rounded shadow p-4 d-flex flex-column justify-content-center align-items-center">
        <Typography
          className="mb-3"
          style={{ textDecoration: "underline" }}
          variant="h4"
          align="center"
          gutterBottom
        >
          Class Details
        </Typography>
        <Typography className="my-2" variant="h5" gutterBottom>
          This is Class{" "}
          <span style={{ color: "#1976d2", fontWeight: "500" }}>
            {sclassDetails && sclassDetails.sclassName}
          </span>
        </Typography>
        <Typography variant="h6" gutterBottom>
          Number of Subjects: {numberOfSubjects}
        </Typography>
        <Typography variant="h6" gutterBottom>
          Number of Students: {numberOfStudents}
        </Typography>
        {getresponse && (
          <GreenButton
            className="my-2"
            variant="contained"
            onClick={() => navigate("/ngo/class/addstudents/" + classID)}
          >
            Add Students
          </GreenButton>
        )}
        {response && (
          <GreenButton
            className="my-2"
            variant="contained"
            onClick={() => navigate("/ngo/addsubject/" + classID)}
          >
            Add Subjects
          </GreenButton>
        )}
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <Box sx={{ width: "100%", typography: "body1", zIndex: 0 }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "#f5f5f5", zIndex: 0 }}>
                <TabList
                  onChange={handleChange}
                  sx={{
                    width: "90%",
                    margin: "auto",
                    bgcolor: "",

                    // zIndex: 1,
                    zIndex: 0,
                  }}
                >
                  <Tab label="Details" value="1" />
                  <Tab label="Subjects" value="2" />
                  <Tab label="Students" value="3" />
                  {/* <Tab label="Teachers" value="4" /> */}
                </TabList>
              </Box>
              <Container
                // className="bg-white"
                sx={{ marginTop: "3rem", marginBottom: "2rem", zIndex: 0 }}
              >
                <TabPanel value="1">
                  <ClassDetailsSection />
                </TabPanel>
                <TabPanel value="2" sx={{ zIndex: 0 }}>
                  <ClassSubjectsSection />
                </TabPanel>
                <TabPanel value="3">
                  <ClassStudentsSection />
                </TabPanel>
                {/* <TabPanel value="4">
                  <ClassTeachersSection />
                </TabPanel> */}
              </Container>
            </TabContext>
          </Box>
        </>
      )}
      <Popup
        message={message}
        setShowPopup={setShowPopup}
        showPopup={showPopup}
      />
    </>
  );
};

export default ClassDetails;
