import axios from "axios";
import {
  getRequest,
  getSuccess,
  getFailed,
  getError,
  postDone,
  doneSuccess,
} from "./teacherSlice";

export const getAllTeachers = (id) => async (dispatch) => {
  dispatch(getRequest());

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/Teachers/${id}`
    );
    if (result.data.message) {
      dispatch(getFailed(result.data.message));
    } else {
      dispatch(getSuccess(result.data));
    }
  } catch (error) {
    dispatch(getError(error));
  }
};

export const getTeacherDetails = (id) => async (dispatch) => {
  dispatch(getRequest());

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/Teacher/${id}`
    );
    if (result.data) {
      dispatch(doneSuccess(result.data));
    }
  } catch (error) {
    dispatch(getError(error));
  }
};
export const makeTeacherHead = (payload, callback) => async (dispatch) => {
  dispatch(getRequest());

  try {
    const result = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/changeClassTeacher`,
      payload
    );
    if (result.data) {
      dispatch(doneSuccess(result.data));
      callback(true);
    }
  } catch (error) {
    dispatch(getError(error));
    callback(false);
  }
};

export const updateTeachSubject =
  (teacherId, teachSubject) => async (dispatch) => {
    dispatch(getRequest());

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/TeacherSubject`,
        { teacherId, teachSubject },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      dispatch(postDone());
    } catch (error) {
      dispatch(getError(error));
    }
  };

export const removeTeacher = (teacherId) => async (dispatch) => {
  dispatch(getRequest());

  try {
    await axios.delete(
      `${process.env.REACT_APP_BASE_URL}/Teacher/${teacherId}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    dispatch(postDone());
  } catch (error) {
    dispatch(getError(error));
  }
};
